import { Inventory, InventoryItem, Strain } from "../../types";
import { useEffect, useState } from "react";

import { AddStrain } from "../strains/add";
import { Modal } from "../../ui/modal";
import { StrainApi } from "../../apis/strain";
import { StrainPreview } from "../strains/preview";
import { styles } from "../../ui/styles";

type InventoryManagementProps = {
  inventory: Inventory;
  inventoryItems: InventoryItem[];
};
export const InventoryManagement = ({
  inventory,
  inventoryItems,
}: InventoryManagementProps) => {
  const [open, setOpen] = useState(false);
  const [inventoryStrains, setInventoryStrains] = useState<Strain[]>([]);
  useEffect(() => {
    (async () => {
      let inventoryItemIds: string[] = [];
      inventoryItems.forEach((item) => {
        if (item.id !== undefined) {
          inventoryItemIds.push(item.strainId);
        }
      });
      setInventoryStrains(await new StrainApi().getManyById(inventoryItemIds));
    })();
  }, [inventoryItems.length]);
  return (
    <section className="shadow-md gap-5 justify-self-center rounded-md grid grid-flow-row p-2">
      <section>
        <h2 className="text-xl font-bold">Strains</h2>
        <button
          onClick={(e) => setOpen(true)}
          className={styles.button.confirm}
        >
          Add Strains
        </button>
      </section>
      <section className="grid lg:grid-cols-3 gap-2">
        {inventoryStrains.map((strain) => (
          <StrainPreview strain={strain}></StrainPreview>
        ))}
      </section>
      <section>
        {/* Strains */}
        <Modal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        >
          {inventory.id && <AddStrain inventoryId={inventory.id}></AddStrain>}
        </Modal>
      </section>
    </section>
  );
};
