import { Inventory } from "../../types";
import { Link } from "react-router-dom";
import { styles } from "../../ui/styles";

type InventoryProps = { inventory: Inventory };
export const InventoryPreview = ({ inventory }: InventoryProps) => {
  return (
    <div className="shadow-md p-2 bg-white rounded-md grid gap-2 justify-between">
      <section>
        <div className="font-bold text-xl">{inventory.name}</div>
        <div>{inventory.description}</div>
      </section>
      <Link
        className={styles.button.confirm}
        to={`/inventories/${inventory.id}`}
      >
        Manage
      </Link>
    </div>
  );
};
