import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LogoutButton from "../../../components/authentication/logoutButton";
import { NavProps } from "..";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { styles } from "../../styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

export const MobileNav = ({ links }: NavProps) => {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth0();

  return (
    <div className="text-white w-full font-bold flex">
      {open ? (
        <div className="h-screen w-screen flex gap-5 flex-col content-center">
          <button
            className={
              styles.button.confirm +
              " w-min self-center shadow-none rotate-90 hover:rotate-180 transition duration-1000"
            }
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon size="lg" icon={fas.faBars}></FontAwesomeIcon>
          </button>
          {Object.entries(links).map(([text, { path }]) => (
            <Link className={styles.button.confirm + " bg-white text-black"} to={path}>
              {text}
            </Link>
          ))}

          {isAuthenticated ? (
            <LogoutButton />
          ) : (
            <Link className={styles.button.confirm} to="/login">
              Login
            </Link>
          )}
        </div>
      ) : (
        <div className="flex flex-1 w-full items-center justify-between">
          <div>Batchfinders</div>
          <button
            className={
              styles.button.confirm +
              " w-min self-center shadow-none rotate-0 hover:-rotate-90 transition duration-1000"
            }
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon
              size="lg"
              className=""
              icon={fas.faBars}
            ></FontAwesomeIcon>
          </button>
        </div>
      )}
    </div>
  );
};
