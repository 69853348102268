import InventoryApi from "../../apis/inventory";
import { create } from "domain";
import { styles } from "../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

type CreateInventoryStateType = {
  name: string;
  description: string;
};
const defaultCreateInventoryState = {
  name: "",
  description: "",
};
export const CreateInventory = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [createInventoryState, setCreateInventoryState] =
    useState<CreateInventoryStateType>(defaultCreateInventoryState);

  const handleClickCreate = async () => {
    if (!user || !user.sub) throw new Error("user undefined");
    new InventoryApi().createForUser(await getAccessTokenSilently(), {
      name: createInventoryState.name,
      ownerId: user.sub,
      description: createInventoryState.description,
    });
  };

  return (
    <div className=" grid gap-5">
      <h2 className="text-2xl">Create Inventory</h2>
      <section className="grid gap-2">
        <label>Name</label>
        <input
          onChange={(e) => {
            setCreateInventoryState({
              ...createInventoryState,
              name: e.target.value,
            });
          }}
          value={createInventoryState.name}
          className={styles.input}
        ></input>
        <label>Description</label>
        <textarea
          onChange={(e) => {
            setCreateInventoryState({
              ...createInventoryState,
              description: e.target.value,
            });
          }}
          value={createInventoryState.description}
          className={styles.input}
        ></textarea>
      </section>
      <section>
        <button
          className={styles.button.confirm + " lg:w-full"}
          onClick={handleClickCreate}
        >
          Create
        </button>
      </section>
    </div>
  );
};
