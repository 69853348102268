import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface LoadingProps {
  size?: SizeProp; // Optional prop for spinner size
}

export const Loading: React.FC<LoadingProps> = ({ size = "xl" }) => {
  return (
    <div className="inline-flex items-center justify-center animate-spin h-full w-full">
      <FontAwesomeIcon icon={faSpinner} size={size} className="text-gray-500" />
    </div>
  );
};
