import {
  LikeActionType,
  LikeContext,
  LikeDispatchContext,
} from "../../../context/like";
import { useContext, useEffect, useState } from "react";

import LikeApi from "../../../apis/like";
import { Navigate } from "react-router-dom";
import { Pagination } from "../../../ui/pagination";
import { Search } from "../../../ui/search";
import { Strain } from "../../../types";
import { StrainApi } from "../../../apis/strain";
import { StrainPreview } from "../preview";
import { styles } from "../../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";

type StrainsSearchState = {
  searchValue: string;
  strains: Strain[];
  currentIndex: number;
  itemsPerPage: number;
};
export const StrainsSearch = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const likeDispatch = useContext(LikeDispatchContext);
  const likeState = useContext(LikeContext);
  // == state ==
  const defaultStrainSearchState: StrainsSearchState = {
    searchValue: "",
    strains: [],
    currentIndex: 0,
    itemsPerPage: 10,
  };
  const [state, setState] = useState<StrainsSearchState>(
    defaultStrainSearchState
  );

  // == event handlers ==
  /**
   *
   *
   *
   * @param e
   */
  const handleSearchInputChange = (newString: string) => {
    setState({ ...state, searchValue: newString });
  };

  const handleIncrementPage = () => {
    if (state.currentIndex + state.itemsPerPage < state.strains.length) {
      setState({
        ...state,
        currentIndex: state.currentIndex + state.itemsPerPage,
      });
    }
  };
  const handleDecrementPage = () => {
    if (state.currentIndex - state.itemsPerPage > 0) {
      setState({
        ...state,
        currentIndex: state.currentIndex - state.itemsPerPage,
      });
    } else {
      setState({
        ...state,
        currentIndex: 0,
      });
    }
  };
  const handleSetItemsPerPage = (e: any) => {
    setState({ ...state, itemsPerPage: Number(e.target.value) });
  };

  const handleClickSearch = async () => {
    const strains = await new StrainApi().search(state.searchValue);
    setState({ ...state, strains, currentIndex: 0 });
  };

  useEffect(() => {
    if (!likeDispatch) {
      alert("no dispatch");
      return;
    }

    if (state.strains.length === 0) {
      likeDispatch({ type: LikeActionType.set, likes: [] });
    } else {
      (async () => {
        if (user === undefined || user.sub === undefined) {
          throw new Error("Unable to get user likes: userId undefined");
        }

        const likes = await new LikeApi().getByUser(
          await getAccessTokenSilently(),
          user.sub
        );

        likeDispatch({ type: LikeActionType.set, likes });
      })();
    }
  }, [state.strains.length]);
  return (
    <>
      {/* Search Input */}
      <section className="grid gap-2 content-start shadow-lg p-6 rounded-lg bg-white w-full mx-auto">
        <Search
          searchTerm={state.searchValue}
          handleChangeSearchTerm={(newSearchTerm) =>
            handleSearchInputChange(newSearchTerm)
          }
          handleClickSearch={handleClickSearch}
        ></Search>
      </section>
      {state.strains.length > 0 && (
        <div className="grid gap-5 ">
          {/* Strains List */}
          <Pagination
            currentIndex={state.currentIndex}
            numItems={state.strains.length}
            itemsPerPage={state.itemsPerPage}
            setItemsPerPage={handleSetItemsPerPage}
            increment={handleIncrementPage}
            decrement={handleDecrementPage}
          ></Pagination>
          <section className="grid gap-4 lg:grid-cols-3">
            {state.strains
              .slice(
                state.currentIndex,
                state.currentIndex + state.itemsPerPage
              )
              .map((strain) => (
                <StrainPreview key={strain._id} strain={strain} />
              ))}
          </section>
          {/* Pagination Controls */}
          <Pagination
            currentIndex={state.currentIndex}
            numItems={state.strains.length}
            itemsPerPage={state.itemsPerPage}
            setItemsPerPage={handleSetItemsPerPage}
            increment={handleIncrementPage}
            decrement={handleDecrementPage}
          ></Pagination>
        </div>
      )}
    </>
  );
};
