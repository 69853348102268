import { AuthenticationPage } from "../views/authentication";
import { CalculatorPage } from "../views/calculator";
import { FeedbackPage } from "../views/feedback";
import { HomePage } from "../views/home";
import { InventoryPage } from "../views/inventory";
import { PricingPage } from "../views/pricing";
import { ProfilePage } from "../views/profile";
import { StrainPage } from "../views/strains/strain";
import { StrainsPage } from "../views/strains";
import { StrainsRecommendationPage } from "../views/strains/recommendation";
import { StrainsSearchPage } from "../views/strains/search";
import { createBrowserRouter } from "react-router-dom";
const routes = [
  { path: "/", element: <HomePage /> },
  {
    path: "/authentication",
    element: <AuthenticationPage />,
  },
  { path: "/profile", element: <ProfilePage /> },
  { path: "/recommendations", element: <ProfilePage /> },
  {
    path: "/inventories",
    children: [
      {
        path: ":inventoryId",
        element: <InventoryPage></InventoryPage>,
      },
    ],
  },
  { path: "/feedback", element: <FeedbackPage></FeedbackPage> },
  { path: "/pricing", element: <PricingPage></PricingPage> },
  { path: "/calculator", element: <CalculatorPage></CalculatorPage> },
  {
    path: "/strains",
    element: <StrainsPage />,
    children: [
      { path: "recommendations", element: <StrainsRecommendationPage /> },

      { path: ":_id", element: <StrainPage></StrainPage> },
      { path: "search", element: <StrainsSearchPage /> },
    ],
  },
];
export const router = createBrowserRouter(routes);
