import HubSpotForm from "../hubspot";

export const Footer = () => {
  return (
    <footer className="bg-green-700 w-full">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="bg-white rounded-md shadow-md overflow-hidden">
          <div className="p-5">
            <h2 className="text-2xl font-bold text-green-700 mb-5">
              Stay in Touch
            </h2>
            <HubSpotForm
              formId="55447f51-56fe-460d-a660-76a15e201efe"
              portalId="23664382"
            />
          </div>
        </div>
        <div className="text-center text-white mt-10">
          <p>© {new Date().getFullYear()} Batchfinders. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};
