export const Welcome = () => {
  return (
    <div className="container max-w-xl mx-auto p-5 shadow-md rounded-md">
      <h1 className="text-3xl font-bold text-center my-6">
        Welcome to Batchfinders Beta
      </h1>

      <h2 className="text-2xl font-semibold my-4">
        Discover, Connect, and Innovate with Batchfinders
      </h2>

      <p className="my-2">
        Welcome to the forefront of cannabis discovery – Batchfinders is your
        gateway to exploring the vast world of cannabis strains with precision
        and ease. As we launch our beta, we invite you to be among the first to
        experience a platform designed for enthusiasts, connoisseurs, and
        industry professionals alike.
      </p>

      <section className="my-8">
        <h3 className="text-xl font-semibold mb-3">Strain Search Tool</h3>
        <p className="my-2">
          Dive into our comprehensive Strain Search Tool, where the perfect
          profile is just a few clicks away. Whether you're seeking something
          specific or just browsing, our intuitive search functionality brings
          the world of strains to your fingertips. Explore flavors, effects, and
          genetics with data-driven insights that cater to both your curiosity
          and professional needs.
        </p>
      </section>

      <section className="my-8">
        <h3 className="text-xl font-semibold mb-3">
          Personalized User Profiles
        </h3>
        <p className="my-2">
          Create your personalized profile to unlock the full potential of
          Batchfinders. Your profile is the command center for managing your
          preferences, tracking your searches, and staying updated with the
          latest in cannabis strain information.
        </p>
      </section>

      <section className="my-8">
        <h3 className="text-xl font-semibold mb-3">
          API Access for Developers
        </h3>
        <p className="my-2">
          Innovation doesn't stop at discovery. With Batchfinders, you can
          generate API keys directly from your profile, granting you access to
          our database for integration into your own applications. Whether for
          research, retail, or personal projects, our API provides the
          flexibility and data you need to power your ideas.
        </p>
      </section>

      <section className="my-8">
        <h3 className="text-xl font-semibold mb-3">Join the Community</h3>
        <p className="my-2">
          Batchfinders is more than a search tool – it's a community. As a beta
          user, your feedback is invaluable. Help us refine and enhance the
          experience with your insights. Together, we're not just exploring
          strains; we're shaping the future of cannabis tech.
        </p>
      </section>

      <section className="my-8">
        <h3 className="text-xl font-semibold mb-3">
          Embark on Your Cannabis Journey
        </h3>
        <p className="my-2">
          Ready to begin? Use our Strain Search Tool to start your exploration
          or visit your profile to generate your API keys today. Welcome to
          Batchfinders – where every search leads to discovery.
        </p>
      </section>
    </div>
  );
};
