import { PropsWithChildren, useState } from "react";

type VerticalTabsProps = PropsWithChildren & {
  vertical?: boolean;
  elements: { [key: string]: any };
};
export const Tabs = ({ elements, vertical }: VerticalTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };
  let tabStyle;
  let listStyle;
  if (vertical) {
    listStyle = "flex flex-col";
    tabStyle = "flex w-full flex-row  gap-2";
  } else {
    listStyle = "flex gap-2 flex-row";
    tabStyle = "flex  w-full flex-col  gap-2";
  }
  return (
    <div className={tabStyle}>
      {Object.keys(elements).length > 1 && (
        <div className={listStyle}>
          {Object.keys(elements).map((tabName, i) => {
            return (
              <div className="grid grid-flow-col items-center gap-2 ">
                <div
                  className="hover:bg-gray-300 p-2 rounded-md"
                  onClick={() => handleSelectTab(i)}
                >
                  {tabName}
                </div>
                {i + 1 < Object.keys(elements).length && (
                  <div className=" border-solid border-l-2 h-4 border-gray-500"></div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {Object.values(elements)[selectedTab]}
    </div>
  );
};
