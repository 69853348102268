import { useEffect, useState } from "react";

import { CustomerApi } from "../../apis/customer";
import { Helmet } from "react-helmet";
import { Loading } from "../../ui/loading";
import { useAuth0 } from "@auth0/auth0-react";

export const Pricing = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  let [userSession, setUserSession] = useState<any>();

  useEffect(() => {
    (async () => {
      if (user !== undefined && user.sub !== undefined) {
        const session = await new CustomerApi().getSession(
          await getAccessTokenSilently(),
          user.sub
        );
        setUserSession(session);
      }
    })();
  }, [user]);

  if (isLoading) {
    return <Loading></Loading>;
  }
  if (!user) {
    return <div>user not found</div>;
  }

  if (!user.sub) {
    return <div>user.sub undefined</div>;
  }

  return (
    <>
      <div className="">
        <Helmet>
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
        </Helmet>
        {userSession && userSession.client_secret ? (
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={user.sub.split("|")[1]}
            customer-email={user.email}
            customer-session-client-secret={userSession.client_secret}
          ></stripe-pricing-table>
        ) : (
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={user.sub.split("|")[1]}
            customer-email={user.email}
          ></stripe-pricing-table>
        )}
      </div>
    </>
  );
};

// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
