import { Dropdown } from "../dropdown";
import { styles } from "../styles";

type PaginationProps = {
  currentIndex: number;
  numItems: number;
  itemsPerPage?: number;
  setItemsPerPage?: (e: any) => void;
  increment: () => void;
  decrement: () => void;
};
export const Pagination = ({
  currentIndex = 0,
  numItems,
  itemsPerPage = 1,
  setItemsPerPage,
  increment,
  decrement,
}: PaginationProps) => {
  return (
    <div className="grid gap-2">
      <div className="w-full flex gap-5 place-items-center flex-col xl:flex-row justify-between">
        {currentIndex > 0 && (
          <button className={styles.button.confirm + " "} onClick={decrement}>
            Previous
          </button>
        )}
        {numItems > 1 && (
          <div>
            {itemsPerPage === 1 ? (
              <div>
                {currentIndex + 1} of {numItems}
              </div>
            ) : currentIndex + itemsPerPage > numItems ? (
              <div>
                {currentIndex + 1} - {numItems}
              </div>
            ) : (
              <div>
                {currentIndex + 1} - {currentIndex + itemsPerPage} of {numItems}
              </div>
            )}
          </div>
        )}
        {currentIndex + itemsPerPage < numItems && (
          <button className={styles.button.confirm} onClick={increment}>
            Next
          </button>
        )}
      </div>
      {itemsPerPage > 1 && setItemsPerPage && (
        <div className="flex place-items-center gap-2">
          <label>Items Per Page</label>
          <Dropdown
            value={itemsPerPage}
            handleSelect={setItemsPerPage}
            options={[5, 10, 50, 100]}
          ></Dropdown>
        </div>
      )}
    </div>
  );
};
