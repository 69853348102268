import { RequestBuilder, RequestType } from "../RequestBuilder";

import { IApi } from "..";
import { Like } from "../../types";

export default class LikeApi implements IApi<Like> {
  constructor(
    private readonly gateWayUrl = process.env.REACT_APP_GATEWAY_URL
  ) {}

  /**
   *
   * @param _id the id of the like you would like to see
   */
  getById(_id: string): Promise<Like> {
    throw new Error("Method not implemented.");
  }
  /**
   *
   * @returns a list of a user's likes
   */
  async getByUser(token: string, userId: string): Promise<Like[]> {
    const request = RequestBuilder.buildRequest(
      RequestType.Get,
      `/user/${userId}/like`,
      undefined,
      token
    );
    let likes: Like[] = await (await request).json();

    return likes;
  }

  /**
   *
   * @returns
   */
  async createLike(token: string, like: Like): Promise<string> {
    const request = RequestBuilder.buildRequest(
      RequestType.Post,
      `/like`,
      JSON.stringify(like),
      token
    );
    let likeId: string = await (await request).json();
    return likeId;
  }

  async deleteById(token: string, likeId: string): Promise<void> {
    const request = RequestBuilder.buildRequest(
      RequestType.Delete,
      `/like/${likeId}`,
      undefined,
      token
    );

    await (await request).json();
  }
}
