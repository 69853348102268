import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Loading } from "../../../ui/loading";
import { Page } from "../../../ui/page";
import { Strain } from "../../../types";
import { StrainApi } from "../../../apis/strain";
import { StrainPreview } from "../../../components/strains/preview";
import { request } from "http";
import { styles } from "../../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";

export const StrainsRecommendationPage = () => {
  const { getAccessTokenSilently, isLoading, user } = useAuth0();
  const strainApi: StrainApi = new StrainApi();
  const [recommendations, setRecommendations] = useState<Strain[]>([]);
  const [loading, setLoading] = useState(false);
  const [requestedOnce, setRequestedOnce] = useState(false);

  const refreshRecommendations = async () => {
    try {
      console.debug("refreshing");
      setLoading(true);
      setRecommendations([
        ...recommendations,
        ...(await strainApi.recommendation(await getAccessTokenSilently())),
      ]);
      if (requestedOnce === false) setRequestedOnce(true);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    await refreshRecommendations();
  };

  useEffect(() => {
    if (requestedOnce === false) refreshRecommendations();
  }, [requestedOnce]);

  return (
    <Page
      header="Strain Recommendations"
      title="Recommendations | Batchfinders"
    >
      <div className="grid grid-cols-1 lg:grid-cols-3 h-full gap-2 items-start justify-center items-center  ">
        {requestedOnce &&
          recommendations?.map((recommendation) => (
            <StrainPreview strain={recommendation} />
          ))}
      </div>
      {loading && <Loading />}
      <button
        disabled={loading}
        onClick={(e) => handleClick(e)}
        className={styles.button.confirm}
      >
        {requestedOnce || loading ? "More" : "Get Recommendations"}
      </button>
    </Page>
  );
};
