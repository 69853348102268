import { Navigate, Outlet } from "react-router-dom";

import { Footer } from "../../components/footer";
import { useAuth0 } from "@auth0/auth0-react";

export const StrainsPage = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) return <div>...loading</div>;
  if (!isAuthenticated) return <Navigate to="/authentication" />;
  return <Outlet></Outlet>;
};
