import {
  LikeActionType,
  LikeContext,
  LikeDispatchContext,
} from "../../context/like";
import { useContext, useEffect, useState } from "react";

import { AccessToken } from "../../components/authentication/accessToken";
import { CreateOrganization } from "../../components/organization/createOrganization";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet";
import { Inventories } from "../../components/inventory/Inventories";
import LikeApi from "../../apis/like";
import LogoutButton from "../../components/authentication/logoutButton";
import { Page } from "../../ui/page";
import { Strain } from "../../types";
import { StrainApi } from "../../apis/strain";
import { StrainLikes } from "../../components/strains/likes";
import { StrainPreview } from "../../components/strains/preview";
import { Subscription } from "../../components/profile/subscription";
import { Tabs } from "../../ui/tabs";
import { UserInfo } from "../../components/profile/userInfo";
import { useAuth0 } from "@auth0/auth0-react";

export const ProfilePage = () => {
  const { isLoading, user, getAccessTokenSilently } = useAuth0();

  const strainApi = new StrainApi();

  const [likedStrains, setLikedStrains] = useState<Strain[]>([]);
  const likeDispatch = useContext(LikeDispatchContext);
  const likeState = useContext(LikeContext);
  const refreshLikes = () => {
    if (!likeDispatch) {
      alert("no dispatch");
      return;
    }

    if (likedStrains.length === 0) {
      likeDispatch({ type: LikeActionType.set, likes: [] });
    } else {
      (async () => {
        if (user === undefined || user.sub === undefined) {
          throw new Error("Unable to get user likes: userId undefined");
        }

        const likes = await new LikeApi().getByUser(
          await getAccessTokenSilently(),
          user.sub
        );

        likeDispatch({ type: LikeActionType.set, likes });
      })();
    }
  };

  const refreshLikedStrains = async () => {
    if (user !== undefined && user.sub !== undefined)
      setLikedStrains(
        await strainApi.getLikedByUserId(
          user.sub,
          await getAccessTokenSilently()
        )
      );
  };
  useEffect(() => {
    (async () => {
      if (!isLoading && user !== undefined && user.sub !== undefined)
        await Promise.all([refreshLikedStrains()]);
    })();
  }, [isLoading, user]);
  useEffect(() => {
    if (likedStrains.length > 0) refreshLikes();
  }, [likedStrains.length]);

  const tabs = {
    "Strain Likes": likedStrains !== undefined && (
      <StrainLikes strains={likedStrains} />
    ),
    Inventories: <Inventories></Inventories>,
    Subscription: <Subscription></Subscription>,
  };
  if (!isLoading)
    return (
      <Page header="Profile" title="Profile | Batchfinders">
        {user !== undefined && <UserInfo user={user}></UserInfo>}
        <hr className="h-2 w-full"></hr>
        <Tabs elements={tabs}></Tabs>
      </Page>
    );

  return <div className="text-center">Loading...</div>;
};
