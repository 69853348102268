import { CreateInventoryParams, Inventory } from "../../types";
import { RequestBuilder, RequestType } from "../RequestBuilder";

import { IApi } from "..";

export default class InventoryApi implements IApi<Inventory> {
  /**
   *
   * @param _id
   * @param token
   * @returns
   */
  public async getById(_id: string, token?: string): Promise<Inventory> {
    const request = RequestBuilder.buildRequest(
      RequestType.Get,
      `/inventory/${_id}`,
      undefined,
      token
    );
    const inventory: Inventory = await (await request).json();
    return inventory;
  }

  /**
   *
   * @param token
   * @returns
   */
  public async createForOrg(
    token: string,
    params: CreateInventoryParams
  ): Promise<string> {
    const request = RequestBuilder.buildRequest(
      RequestType.Post,
      `/organization/${params.organizationId}/inventory`,
      JSON.stringify(params),
      token
    );
    const inventoryId: string = await (await request).json();
    return inventoryId;
  }

  public async createForUser(
    token: string,
    params: CreateInventoryParams
  ) {
    const request = RequestBuilder.buildRequest(
      RequestType.Post,
      `/user/${params.ownerId}/inventory`,
      JSON.stringify(params),
      token
    );
    const inventoryId: string = await (await request).json();
    return inventoryId;
  }

  public async getByUser(token: string, userId: string) {
    const request = RequestBuilder.buildRequest(
      RequestType.Get,
      `/user/${userId}/inventory`,
      undefined,
      token
    );

    const inventories: Inventory[] = await (await request).json();
    return inventories;
  }
  /**
   *
   * @param token
   * @param orgId
   * @returns
   */
  public async getByOrganization(token: string, orgId: string) {
    const request = RequestBuilder.buildRequest(
      RequestType.Get,
      `/organization/${orgId}/inventory`,
      undefined,
      token
    );

    const inventories: Inventory[] = await (await request).json();
    return inventories;
  }
}
