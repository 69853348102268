import { RequestBuilder, RequestType } from "../RequestBuilder";

import { IApi } from "..";
import { Strain } from "../../types";

enum StrainApiErrors {
  unspecifiedUrl = "Gateway url unspecified",
}
export class StrainApi implements IApi<Strain> {
  constructor(
    private readonly gateWayUrl = process.env.REACT_APP_GATEWAY_URL
  ) {}

  async recommendation(token: string): Promise<Strain[]> {
    const resp = await RequestBuilder.buildRequest(
      RequestType.Get,
      "/strains/recommendation",
      undefined,
      token
    );

    return await resp.json();
  }
  async search(searchString: string): Promise<Strain[]> {
    try {
      if (!this.gateWayUrl) throw new Error(StrainApiErrors.unspecifiedUrl);
      const strains: Strain[] = await (
        await fetch(this.gateWayUrl + "/strains/search/" + searchString)
      ).json();
      return strains;
    } catch (error) {
      alert(error);
      throw error;
    }
  }

  async getManyById(_ids: string[]): Promise<Strain[]> {
    const response = await RequestBuilder.buildRequest(
      RequestType.Get,
      `/strains?_ids=${_ids}`
    );

    return await response.json();
  }

  async getById(_id: string): Promise<Strain> {
    if (!this.gateWayUrl) throw new Error(StrainApiErrors.unspecifiedUrl);
    const strains: Strain[] = await (
      await fetch(this.gateWayUrl + `/strains/${_id}`)
    ).json();

    return strains[0];
  }

  async getLikedByUserId(userId: string, token: string): Promise<Strain[]> {
    if (!this.gateWayUrl) throw new Error(StrainApiErrors.unspecifiedUrl);
    const strains: Strain[] = await (
      await RequestBuilder.buildRequest(
        RequestType.Get,
        `/user/${userId}/strain`,
        undefined,
        token
      )
    ).json();

    return strains;
  }
}
