type DropdownProps = {
  value: any;
  options: any[];
  handleSelect: (e: any) => void;
};
export const Dropdown = ({ value, options, handleSelect }: DropdownProps) => {
  return (
    <select
      value={value}
      className="shadow-md p-2 rounded-md"
      onChange={handleSelect}
    >
      {Object.values(options).map((option) => (
        <option>{option}</option>
      ))}
    </select>
  );
};
