import { RequestBuilder, RequestType } from "../RequestBuilder";

export class UserApi {
  constructor() {}


  async getRolesByUserId(token: string, userId: string) {
    const roles = (
      await RequestBuilder.buildRequest(
        RequestType.Get,
        `/user/${userId}/role`,
        undefined,
        token
      )
    ).json();
  }
}
