import { Link, Navigate, redirect } from "react-router-dom";

import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet";
import LogoutButton from "../../components/authentication/logoutButton";
import { Page } from "../../ui/page";
import { UserApi } from "../../apis/user";
import { Welcome } from "../../components/copy/welcome";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const HomePage = () => {
  return (
    <Page title={"Home | Batchfinders"}>
      <div className="p-5">
        <Welcome />
      </div>
    </Page>
  );
};
