import { Link, Navigate, useNavigate } from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Footer } from "../../components/footer";
import { Helmet } from "react-helmet";
import { Loading } from "../loading";
import { Nav } from "../navigation";
import { SubscriptionApi } from "../../apis/subscription";
import { UserApi } from "../../apis/user";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";

type PageProps = PropsWithChildren & { title: string; header?: string };
export const Page = ({ children, title, header }: PageProps) => {
  let navigate = useNavigate();
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();

  const handleCheckRoles = async () => {
    if (!user) {
      throw new Error("Unable to check roles: user is undefined");
    }
    if (!user.sub) {
      throw new Error("Unable to check roles: user.sub");
    }

    const roles = new UserApi().getRolesByUserId(
      await getAccessTokenSilently(),
      user.sub
    );
  };
  const handleCheckSubscription = async () => {
    try {
      const subscriptionApi = new SubscriptionApi();

      if (!user) {
        throw new Error("Unable to check subscriptions: user is undefined");
      }
      if (!user.sub) {
        throw new Error("Unable to check subscriptions: user.sub");
      }

      const subscriptions = await subscriptionApi.getSubscriptions(
        user.sub,
        await getAccessTokenSilently()
      );
    } catch (error) {
      console.error(error);
      navigate("/pricing");
    }
  };

  useEffect(() => {
    (async () => {
      if (user !== undefined) {
        await handleCheckSubscription();
        await handleCheckRoles();
      }
    })();
  }, [user]);

  if (isLoading) {
    return <Loading></Loading>;
  } else if (!isAuthenticated)
    return <Navigate to={"/authentication"}></Navigate>;
  else
    return (
      <div className="flex flex-col w-screen h-screen overflow-y-hidden overflow-x-hidden">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Nav />
        <main className="flex flex-col flex-1 content-start w-full overflow-y-auto overflow-x-hidden">
          <div className="gap-5 p-5 grid bg-gray-100">
            {header && (
              <h1 className="text-3xl font-bold w-full bg-white rounded-md p-5 shadow-md">
                {header}
              </h1>
            )}
            <div className="grid gap-5 justify-self-center w-full lg:w-3/4">
              {children}
            </div>
            <Link
              to="/feedback"
              className=" bg-white  place-content-center hidden lg:grid rounded-full w-20 h-20 fixed bottom-5 right-10 shadow-md"
            >
              <FontAwesomeIcon
                className="h-10 w-10 text-green-700"
                icon={faClipboardList}
              ></FontAwesomeIcon>
            </Link>
          </div>
          <Footer />
        </main>
      </div>
    );
};
