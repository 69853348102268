import { Navigate } from "react-router-dom";
import { styles } from "../../../ui/styles";

export const Subscription = () => {
  return (
    <div>
      <a
        href="https://billing.stripe.com/p/login/test_dR617qdaS4B81zOeUU"
        target="_blank"
        className={styles.button.confirm}
      >
        Manage Subscription
      </a>
    </div>
  );
};
