import { Link } from "react-router-dom";
import LogoutButton from "../../../components/authentication/logoutButton";
import { NavProps } from "..";
import { useAuth0 } from "@auth0/auth0-react";

export const WebNav = ({ links }: NavProps) => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <div className="flex gap-4">
        {Object.entries(links).map(([text, { path }]) => (
          <Link
            className="text-white hover:bg-green-800 px-3 py-2 rounded transition duration-300"
            to={path}
          >
            {text}
          </Link>
        ))}
        {isAuthenticated ? (
          <LogoutButton />
        ) : (
          <Link
            className="text-white hover:bg-green-800 px-3 py-2 rounded transition duration-300"
            to="/login"
          >
            Login
          </Link>
        )}
      </div>
    </>
  );
};
