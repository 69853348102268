import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import LogoutButton from "../../components/authentication/logoutButton";
import { MobileNav } from "./mobile";
import { WebNav } from "./web";
import { useAuth0 } from "@auth0/auth0-react";

export type NavProps = { links: { [key: string]: { path: string } } };

export const Nav = () => {
  const [{ width, height }, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const links = {
    Home: { path: "/" },
    Search: { path: "/strains/search" },
    Recommendations: { path: "/strains/recommendations" },
    Profile: { path: "/profile" },
    Pricing: { path: "/pricing" },
    // Calculator: { path: "/calculator" },
  };

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    });
  }, []);
  return (
    <nav className="bg-green-700 p-5 flex-0 shadow-md flex z-50">
      {width > 736 ? (
        <WebNav links={links}></WebNav>
      ) : (
        <MobileNav links={links}></MobileNav>
      )}
    </nav>
  );
};
