import {
  LikeActionType,
  LikeContext,
  LikeDispatchContext,
} from "../../../context/like";
import { useContext, useEffect, useState } from "react";

import LikeApi from "../../../apis/like";
import { Pagination } from "../../../ui/pagination";
import { Search } from "../../../ui/search";
import { Strain } from "../../../types";
import { StrainPreview } from "../preview";
import { useAuth0 } from "@auth0/auth0-react";

export type StrainLikesProps = { strains: Strain[] };
export const StrainLikes = ({ strains }: StrainLikesProps) => {
  const { user, getAccessTokenSilently, isLoading } = useAuth0();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const setItemsPPage = (e: any) => {
    setCurrentIndex(0);
    setItemsPerPage(Number(e.target.value));
  };
  const handleIncrement = () => {
    if (currentIndex + itemsPerPage < getFilteredStrains().length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    } else {
    }
  };
  const handleDecrement = () => {
    if (currentIndex - itemsPerPage > 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    } else {
      setCurrentIndex(0);
    }
  };

  const getFilteredStrains = () => {
    if (searchTerm === "") return strains;
    return strains.filter((strain) => {
      const lowerCaseTerm = searchTerm.toLowerCase();

      if (
        !!strain.descriptions.find((description) =>
          description.description.toLowerCase().includes(lowerCaseTerm)
        )
      ) {
        return true;
      } else {
        return strain.name.toLowerCase().includes(lowerCaseTerm);
      }
    });
  };
  if (isLoading) return <div>...loading</div>;
  return (
    <div className="gap-5 flex flex-col">
      <h2 className="text-3xl">Liked Strains</h2>
      <div className="flex flex-col w-full  md:w-1/2  gap-2">
        <label>Search</label>
        <Search
          searchTerm={searchTerm}
          handleChangeSearchTerm={(e) => {
            setCurrentIndex(0);
            setSearchTerm(e);
          }}
          handleClickSearch={undefined}
        ></Search>
      </div>
      <Pagination
        currentIndex={currentIndex}
        numItems={getFilteredStrains().length}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPPage}
        decrement={handleDecrement}
        increment={handleIncrement}
      ></Pagination>
      <div className="grid gap-5 grid-flow-row lg:grid-cols-3 ">
        {getFilteredStrains()
          .slice(currentIndex, currentIndex + itemsPerPage)
          .map((strain) => {
            return <StrainPreview strain={strain} />;
          })}
      </div>
      <Pagination
        currentIndex={currentIndex}
        numItems={getFilteredStrains().length}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPPage}
        decrement={handleDecrement}
        increment={handleIncrement}
      ></Pagination>
    </div>
  );
};
