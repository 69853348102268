import { Dispatch, PropsWithChildren, createContext, useReducer } from "react";

import { Inventory } from "../../types";

type InventoryState = { inventories: Map<string, Inventory> };
const defaultInventoryState: InventoryState = {
  inventories: new Map(),
};
export const InventoryContext = createContext(defaultInventoryState);
export const InventoryDispatchContext = createContext<
  Dispatch<InventoryAction> | undefined
>(undefined);

export enum InventoryActionType {
  set,
}
export type InventoryAction = {
  type: InventoryActionType;
  inventories: Inventory[];
};
const InventoryReducer = (
  state: InventoryState,
  action: InventoryAction
): InventoryState => {
  switch (action.type) {
    case InventoryActionType.set:
      const inventories = action.inventories;
      const inventoryMap = new Map(
        inventories.map((inventory) => {
          if (inventory.id) return [inventory.id, inventory];
          else throw new Error("Unable to map inventory. id undefined");
        })
      );
      return { ...state, inventories: inventoryMap };

    default:
      throw new Error("Unsupported inventory action type");
  }
};

export const InventoryContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(InventoryReducer, defaultInventoryState);
  return (
    <InventoryContext.Provider value={state}>
      <InventoryDispatchContext.Provider value={dispatch}>
        {children}
      </InventoryDispatchContext.Provider>
    </InventoryContext.Provider>
  );
};
