import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { Page } from "../../../ui/page";
import { StrainsSearch } from "../../../components/strains/search";
import { useAuth0 } from "@auth0/auth0-react";

export const StrainsSearchPage = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) return <div>loading ...</div>;
  return (
    <Page header="Strain Search" title="Search | Batchfinders">
      <StrainsSearch></StrainsSearch>
    </Page>
  );
};
