import { Page } from "../../ui/page";

export const FeedbackPage = () => {
  return (
    <Page title="Feedback | Batchfinders">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLScFf58W9nMijaSk50MhL7nCOysPeKoxqq_-XBOIYmzA7UUVng/viewform?embedded=true"
        className="w-full"
        height="4500"
      >
        Loading…
      </iframe>
    </Page>
  );
};
