import {
  InventoryItem,
  InventoryItemCategory,
  InventoryItemUnit,
} from "../../types";
import { RequestBuilder, RequestType } from "../RequestBuilder";

import { IApi } from "..";

export class InventoryItemApi implements IApi<InventoryItem> {
  getById(_id: string): Promise<InventoryItem> {
    throw new Error("Method not implemented.");
  }

  async getByInventory(
    token: string,
    inventoryId: string
  ): Promise<InventoryItem[]> {
    const inventoryItems = (
      await RequestBuilder.buildRequest(
        RequestType.Get,
        `/inventory/${inventoryId}/item`,
        undefined,
        token
      )
    ).json();

    return inventoryItems;
  }
  async createForUser(
    token: string,
    inventoryId: string,
    strainId: string,
    orgId?: string,
    category?: InventoryItemCategory,
    quantity?: number,
    unit?: InventoryItemUnit
  ) {
    await RequestBuilder.buildRequest(
      RequestType.Post,
      `/inventory/${inventoryId}/item`,
      JSON.stringify({ strainId, orgId, category, quantity, unit }),
      token
    );
  }
}
