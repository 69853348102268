import React, { useEffect } from "react";

const HubSpotForm = ({
  portalId,
  formId,
}: {
  portalId: string;
  formId: string;
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          region: "na1",
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",
        });
      }
    });

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, [portalId, formId]); // Dependencies array for the useEffect hook

  return <div id="hubspotForm"></div>;
};

export default HubSpotForm;
