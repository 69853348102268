import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";
import { Like, LikeType } from "../../types";

type LikeState = { strainIdsToLikes: { [key: string]: Like } };
const defaultLikeState: LikeState = { strainIdsToLikes: {} };

export const LikeContext = createContext(defaultLikeState);
export const LikeDispatchContext = createContext<
  Dispatch<LikeAction> | undefined
>(undefined);

export enum LikeActionType {
  set,
  clear,
  add,
  remove,
}
export type LikeAction = { type: LikeActionType; likes: Like[] };

const LikeReducer = (state: LikeState, action: LikeAction): LikeState => {
  switch (action.type) {
    case LikeActionType.set:
      let idsToLikes: { [key: string]: Like } = {};
      action.likes.forEach((like) => {
        if (like.id === undefined) {
          throw new Error(`Unable to add like to state. Like id ${like.id}`);
        }
        if (like._id) {
          idsToLikes[like.itemId] = like;
        }
      });
      return { ...state, strainIdsToLikes: idsToLikes };
    case LikeActionType.clear:
      return { ...state, strainIdsToLikes: {} };

    default:
      throw new Error(`Invalid Like Action: ${action.type}`);
  }
};

export const LikeContextProvider = (props: PropsWithChildren) => {
  const [state, dispatch] = useReducer(LikeReducer, defaultLikeState);
  return (
    <LikeContext.Provider value={state}>
      <LikeDispatchContext.Provider value={dispatch}>
        {props.children}
      </LikeDispatchContext.Provider>
    </LikeContext.Provider>
  );
};
