import moment, { Moment, months } from "moment";
import { useEffect, useState } from "react";

enum Cannabinoids {
  THC = "THC",
}
enum Terpenes {
  Limonene = "Limonene",
  Pinene = "Pinene",
  Carrypholene = "Carrypholene",
  Linalool = "Linalool",
}
export const Calculator = () => {
  const defaultDate = moment(Date.now());
  const [duration, setDuration] = useState<number>(0);
  const [testedDate, setTestedDate] = useState<Moment>(defaultDate);
  const [currentDate, setCurrentDate] = useState<Moment>(defaultDate);
  const [estimatedCannabinoids, setEstimatedCannabinoids] = useState<{
    [key: string]: number;
  }>({});
  const [estimatedTerpenes, setEstimatedTerpenes] = useState<{
    [key: string]: number;
  }>({});
  const handleEstimatedCannabinoidChange = (
    cannabinoid: Cannabinoids,
    percentage: number
  ) => {
    setEstimatedCannabinoids({
      ...estimatedCannabinoids,
      [cannabinoid]: percentage,
    });
  };
  const handleEstimatedTerpeneChange = (
    terpene: Terpenes,
    percentage: number
  ) => {
    setEstimatedTerpenes({
      ...estimatedTerpenes,
      [terpene]: percentage,
    });
  };

  useEffect(() => {
    setDuration(currentDate.diff(testedDate, "days") / 30.437);
  }, [currentDate, testedDate]);

  const estimateCannabinoidHalflife = (
    cannabinoid: Cannabinoids,
    percentage: number
  ) => {
    const k = 0.04;
    const t = duration;
    switch (cannabinoid) {
      case Cannabinoids.THC:
        return estimatedCannabinoids[cannabinoid] * Math.exp(-k * t);
      default:
        return 0;
    }
  };
  return (
    <main className="p-5 rounded-md shadow-md w-max place-self-center">
      <section>
        <h1 className="text-4xl">Terpene Calculator</h1>
        <p>Figure out how strong your weed is</p>
      </section>
      <section className="flex flex-row justify-between gap-2">
        <div className="flex flex-col">
          <label>Test Date</label>
          <input
            value={testedDate.format("YYYY-MM-DD")}
            onChange={(e) => {
              setTestedDate(moment(e.target.value));
            }}
            defaultValue={defaultDate.format("YYYY-MM-DD")}
            type="date"
            className=" shadow-inner"
          ></input>
        </div>
        <div className="flex flex-col">
          <label>Current Date</label>
          <input
            disabled
            value={currentDate.format("YYYY-MM-DD")}
            onChange={(e) => setCurrentDate(moment(e.target.value))}
            defaultValue={defaultDate.format("YYYY-MM-DD")}
            type="date"
            className=" shadow-inner"
          ></input>
        </div>
      </section>
      {/* Tested Values */}
      <section>
        <h2 className="text-3xl">Tested Values</h2>
        <div className="flex gap-2 flex-col hidden">
          <h3 className="text-2xl">Terpenes</h3>
          <div className="flex-wrap flex gap-2">
            {Object.values(Terpenes).map((terpene, i) => {
              return (
                <div className="flex flex-col " key={i}>
                  <label>{terpene}</label>
                  <input
                    onChange={(e) => {
                      handleEstimatedTerpeneChange(
                        terpene,
                        Number(e.target.value)
                      );
                    }}
                    type="number"
                    className=" p-2 rounded-md shadow-inner"
                    step={0.01}
                    min={0}
                    max={100}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex gap-2 flex-col">
          <h3 className=" text-2xl">Cannabinoids</h3>
          <div className="flex flex-row gap-2">
            {Object.values(Cannabinoids).map((cannabinoid, i) => {
              return (
                <div key={i} className="flex flex-col">
                  <label>{cannabinoid}</label>
                  <input
                    type="number"
                    className=" p-2 rounded-md shadow-inner"
                    step={0.01}
                    onChange={(e) => {
                      handleEstimatedCannabinoidChange(
                        cannabinoid,
                        Number(e.target.value)
                      );
                    }}
                    value={estimatedCannabinoids[cannabinoid]}
                    min={0}
                    max={100}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Estimated Values */}
      <section>
        <h2 className="text-3xl">Estimated Current Values</h2>
        <div className="flex gap-2 flex-col hidden">
          <h3 className="text-2xl">Terpenes</h3>
          <div className="flex-wrap flex gap-2">
            {Object.values(Terpenes).map((terpene, i) => {
              return (
                <div key={i} className="flex flex-col ">
                  <label>{terpene}</label>
                  <input
                    disabled
                    type="number"
                    className=" p-2 rounded-md shadow-inner"
                    step={0.01}
                    min={0}
                    max={100}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex gap-2 flex-col">
          <h1 className=" text-2xl">Cannabinoids</h1>
          <div className="flex flex-row gap-2">
            {Object.values(Cannabinoids).map((cannabinoid, i) => {
              return (
                <div key={i} className="flex flex-col">
                  <label>{cannabinoid}</label>
                  <input
                    disabled
                    type="number"
                    value={estimateCannabinoidHalflife(
                      cannabinoid,
                      estimatedCannabinoids[cannabinoid]
                    )}
                    className=" p-2 rounded-md shadow-inner"
                    step={0.01}
                    min={0}
                    max={100}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
};
