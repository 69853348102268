import { useContext, useEffect, useState } from "react";

import { LikeButton } from "../../../components/like";
import { LikeContext } from "../../../context/like";
import { Page } from "../../../ui/page";
import { Pagination } from "../../../ui/pagination";
import { Strain } from "../../../types";
import { StrainApi } from "../../../apis/strain";
import { styles } from "../../../ui/styles";
import { useParams } from "react-router-dom";

type StrainPageProps = {
  strain: Strain;
};
export const StrainPage = () => {
  let { _id } = useParams();

  let [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);
  let [strain, setStrain] = useState<Strain>();
  let [notFound, setNotFound] = useState(false);
  const likeState = useContext(LikeContext);
  const handleIncrement = () => {
    if (strain && currentDescriptionIndex < strain.descriptions.length)
      setCurrentDescriptionIndex(currentDescriptionIndex + 1);
  };
  const handleDecrement = () => {
    if (currentDescriptionIndex > 0)
      setCurrentDescriptionIndex(currentDescriptionIndex - 1);
  };
  useEffect(() => {
    (async () => {
      try {
        if (!_id) return;
        setStrain(await new StrainApi().getById(_id));
      } catch (error) {}
    })();
  }, [_id]);
  const linkList: { [key: string]: string } = {
    Leafly: "https://www.leafly.com/search?q=",
    "I Heart Jane": "https://www.iheartjane.com/products?searchText=",
    Weedmaps: "https://weedmaps.com/results?q=",
  };

  if (!strain) return <div>...loading</div>;
  return (
    <Page title={`${strain.name} | Batchfinders`}>
      <div className="p-2 grid justify-center">
        <section className="p-2 grid gap-5 w-full overflow-hidden shadow-md rounded-md">
          <h2 className="text-3xl">{strain?.name}</h2>
          <hr />
          <h3 className="font-bold underline">{strain.descriptions[currentDescriptionIndex].type}</h3>
          <p className="w-full">
            {strain.descriptions[currentDescriptionIndex].description}
          </p>

          <LikeButton
            liked={likeState.strainIdsToLikes[strain.id] !== undefined}
            itemId={strain.id}
          ></LikeButton>
          <hr></hr>
          <Pagination
            currentIndex={currentDescriptionIndex}
            numItems={strain.descriptions.length}
            itemsPerPage={1}
            setItemsPerPage={function (newNumber: number): void {
              throw new Error(" Function not implemented.");
            }}
            increment={handleIncrement}
            decrement={handleDecrement}
          ></Pagination>
          <div className="flex flex-col justify-center lg:flex-row gap-5">
            {Object.entries(linkList).map(([market, link]) => {
              return (
                <a
                  href={`${link}${strain?.name}`}
                  className={styles.button.confirm + ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Find On {market}
                </a>
              );
            })}
          </div>
        </section>
      </div>
    </Page>
  );
};
