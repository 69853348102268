import { Inventory, InventoryItem } from "../../types";
import {
  InventoryActionType,
  InventoryContext,
  InventoryDispatchContext,
} from "../../context/inventory";
import { useContext, useEffect, useState } from "react";

import InventoryApi from "../../apis/inventory";
import { InventoryItemApi } from "../../apis/inventoryItem";
import { InventoryManagement } from "../../components/inventory/Management";
import { OrganizationContext } from "../../context/organization";
import { Page } from "../../ui/page";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

type InventoryPageParams = {};
export const InventoryPage = ({}: InventoryPageParams) => {
  const { inventoryId } = useParams();
  const { inventories } = useContext(InventoryContext);
  const { currentOrganization } = useContext(OrganizationContext);
  const inventoryDispatch = useContext(InventoryDispatchContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const [inventory, setInventory] = useState<Inventory>();
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  /**
   *
   */
  const refreshInventories = async () => {
    let newInventories;
    if (currentOrganization && currentOrganization.id) {
      newInventories = await new InventoryApi().getByOrganization(
        await getAccessTokenSilently(),
        currentOrganization.id
      );
    } else if (user && user.sub) {
      newInventories = await new InventoryApi().getByUser(
        await getAccessTokenSilently(),
        user.sub
      );
    } else {
      throw new Error("Unable To fetch inventories");
    }

    if (inventoryDispatch) {
      inventoryDispatch({
        type: InventoryActionType.set,
        inventories: newInventories,
      });
    }
  };

  useEffect(() => {
    if ((user && user.sub) || (currentOrganization && currentOrganization.id))
      refreshInventories();
  }, [user]);
  useEffect(() => {
    (async () => {
      if (inventoryId && inventories) {
        setInventory(await inventories.get(inventoryId));
        setInventoryItems(
          await new InventoryItemApi().getByInventory(
            await getAccessTokenSilently(),
            inventoryId
          )
        );
      }
    })();
  }, [inventories]);
  return (
    <Page title={`${inventory?.name} | Batchfinders`} header={inventory?.name}>
      {inventory ? (
        <InventoryManagement
          inventoryItems={inventoryItems}
          inventory={inventory}
        ></InventoryManagement>
      ) : (
        <div>...loading</div>
      )}
    </Page>
  );
};
