import { LikeContext, LikeDispatchContext } from "../../../context/like";
import { useContext, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LikeButton } from "../../like";
import { Link } from "react-router-dom";
import { Pagination } from "../../../ui/pagination";
import { Strain } from "../../../types";
import { styles } from "../../../ui/styles";

type StrainPreviewProps = { strain: Strain };
export const StrainPreview = ({ strain }: StrainPreviewProps) => {
  const maxStringLength = 200;
  const state = useContext(LikeContext);
  const dispatch = useContext(LikeDispatchContext);
  let [descriptionIndex, setDescriptionIndex] = useState(0);
  const isLiked = false; // This should be derived from some state or props

  const handleIncrement = () => {
    if (descriptionIndex < strain.descriptions.length - 1)
      setDescriptionIndex(descriptionIndex + 1);
  };
  const handleDecrement = () => {
    if (descriptionIndex > 0) setDescriptionIndex(descriptionIndex - 1);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-5 grid gap-5 w-full  justify-self-center h-full">
      <section>
        <h3 className="text-xl font-semibold">{strain.name}</h3>
        <h3 className="font-bold underline">
          {strain.descriptions[descriptionIndex].type}
        </h3>

        <p className="text-gray-600">
          {strain.descriptions[descriptionIndex].description.length >
          maxStringLength
            ? strain.descriptions[descriptionIndex].description.slice(
                0,
                maxStringLength
              ) + "..."
            : strain.descriptions[descriptionIndex].description}
        </p>
      </section>
      <Pagination
        currentIndex={descriptionIndex}
        numItems={strain.descriptions.length}
        increment={handleIncrement}
        decrement={handleDecrement}
      />
      <hr></hr>
      <section className="flex justify-between items-center flex-col lg:flex-row gap-5">
        <Link
          to={`/strains/${strain.id}`}
          className={styles.button.confirm + " text-center"}
        >
          Read More
        </Link>

        <LikeButton
          liked={state.strainIdsToLikes[strain.id] !== undefined}
          itemId={strain.id}
        ></LikeButton>
      </section>
    </div>
  );
};
