import {} from "@fortawesome/fontawesome-svg-core";

import { Like, LikeType } from "../../types";
import {
  LikeActionType,
  LikeContext,
  LikeDispatchContext,
} from "../../context/like";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LikeApi from "../../apis/like";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";

type LikeButtonParams = {
  liked: boolean;
  itemId: string;
};
export const LikeButton = ({ liked, itemId }: LikeButtonParams) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const likeState = useContext(LikeContext);
  const likeDispatch = useContext(LikeDispatchContext);
  const refreshUserLikes = async () => {
    if (!user || user.sub === undefined) {
      throw new Error("Unable to create like: user undefined or null");
    }
    const likeApi = new LikeApi();
    const likes = await likeApi.getByUser(
      await getAccessTokenSilently(),
      user.sub
    );
    if (likeDispatch) likeDispatch({ type: LikeActionType.set, likes });
  };
  const handlePressLikeButton = async () => {
    try {
      if (!user || user.sub === undefined) {
        throw new Error("Unable to create like: user undefined or null");
      }
      const userId = user.sub;
      const likeApi = new LikeApi();

      if (liked === false) {
        const likeId = await likeApi.createLike(
          await getAccessTokenSilently(),
          { userId, itemId }
        );
      } else {
        const likeId = likeState.strainIdsToLikes[itemId]._id;

        if (!likeId) {
          throw new Error("Unable to delete like: likeId undefined or null");
        }

        await likeApi.deleteById(await getAccessTokenSilently(), likeId);
      }

      refreshUserLikes();
    } catch (error) {
      alert(error);
    }
  };
  return (
    <button onClick={() => handlePressLikeButton()}>
      {liked ? (
        <FontAwesomeIcon
          size="xl"
          icon={fas.faHeart}
          className={`text-xl text-red-600 `}
        ></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon
          size="xl"
          icon={far.faHeart}
          className={`text-xl text-gray-400 hover:text-red-600 transition duration-200`}
        ></FontAwesomeIcon>
      )}
    </button>
  );
};
