import { Auth0Provider } from "@auth0/auth0-react";
import { InventoryContextProvider } from "./context/inventory";
import { LikeContextProvider } from "./context/like";
import { OrganizationContextProvider } from "./context/organization";
import ReactGa4 from "react-ga4";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
function App() {
  ReactGa4.initialize("G-DF3FEW5ZY0");
  return (
    <div>
      <OrganizationContextProvider>
        <InventoryContextProvider>
          <LikeContextProvider>
            <Auth0Provider
              domain={process.env.REACT_APP_DOMAIN ?? ""}
              clientId={process.env.REACT_APP_CLIENT_ID ?? ""}
              authorizationParams={{
                redirect_uri: window.location.origin,
                audience: "https://gateway.batchfinders.com",
                scope:
                  "offline_access email profile delete:user:like read:user:like create:user:like update:organization create:organization read:user:organization create:inventory",
              }}
            >
              <RouterProvider router={router} />
            </Auth0Provider>
          </LikeContextProvider>
        </InventoryContextProvider>
      </OrganizationContextProvider>
    </div>
  );
}

export default App;
