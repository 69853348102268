import { User } from "@auth0/auth0-react";

export type UserInfoParams = {
  user: User;
};
export const UserInfo = ({ user }: UserInfoParams) => {
  return (
    <div className="bg-white shadow-md rounded-md w-full p-5">
      <div className="flex flex-col items-center gap-4">
        <img
          className="w-24 h-24 object-cover rounded-full"
          src={user?.picture}
          alt="Profile"
        />
        <div className="text-lg font-semibold">{user?.name}</div>
        <div className="text-gray-500">{user?.email}</div>
      </div>
    </div>
  );
};
