import { ChangeEvent, useContext, useState } from "react";

import { InventoryItemApi } from "../../../apis/inventoryItem";
import { Pagination } from "../../../ui/pagination";
import { Strain } from "../../../types";
import { StrainApi } from "../../../apis/strain";
import { StrainPreview } from "../preview";
import { styles } from "../../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";

type SelectStrainProps = {
  inventoryId: string;
  //   selectedStrainId: string;
  //   handleSelectStrain: (strainId: string) => void;
};
export const AddStrain = ({ inventoryId }: SelectStrainProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [strainIndex, setStrainIndex] = useState(0);
  const [strainSearch, setStrainSearch] = useState("");
  const [strainNum, setStrainNum] = useState<number>(3);
  const [selectedStrainId, setSelectedStrainId] = useState<string | undefined>(
    undefined
  );
  const [strains, setStrains] = useState<Strain[]>([]);
  const handleIncrimentPage = () => {
    if (strainIndex + strainNum < strains.length) {
      setStrainIndex(strainIndex + strainNum);
    } else {
      setStrainIndex(strains.length - strainNum);
    }
  };
  const handleDecrimentPage = () => {
    if (strainIndex - strainNum >= 0) {
      setStrainIndex(strainIndex - strainNum);
    } else {
      setStrainIndex(0);
    }
  };

  const handleStrainSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStrainSearch(e.target.value);
  };

  const handleClickSearch = async () => {
    const strains = await new StrainApi().search(strainSearch);
    setStrains(strains);
  };

  const handleCreateInventoryItem = async (strainId: string) => {
    await new InventoryItemApi().createForUser(
      await getAccessTokenSilently(),
      inventoryId,
      strainId
    );
  };
  return (
    <div className="flex lg:max-w-3xl justify-self-center flex-col gap-2 rounded-md ">
      <h1>Add Strain</h1>
      <div className="flex gap-2">
        <input
          onChange={handleStrainSearchChange}
          className={styles.input}
          placeholder="Strain Name"
        ></input>
        <button
          onClick={handleClickSearch}
          className="bg-green-700 p-2 rounded-md shadow-md"
        >
          Search
        </button>
      </div>
      <div className="grid  gap-2 grid-cols-3 flex-gap">
        {strains
          .slice(strainIndex, strainIndex + strainNum)
          .map((strain, index) => (
            <div
              className={
                selectedStrainId === strain.id
                  ? " border-green-500 rounded-md outline-2 outline-green-500 outline"
                  : ""
              }
              onClick={() => setSelectedStrainId(strain.id)}
            >
              <StrainPreview strain={strain} />
            </div>
          ))}
      </div>
      <Pagination
        currentIndex={strainIndex}
        numItems={strains.length}
        itemsPerPage={strainNum}
        // setItemsPerPage={(e: any) => {
        //   setStrainNum(Number(e.target.value));
        // }}
        increment={handleIncrimentPage}
        decrement={handleDecrimentPage}
      ></Pagination>
      <button
        disabled={!selectedStrainId}
        className={styles.button.confirm}
        onClick={() => {
          if (!selectedStrainId)
            throw new Error(
              "Unable to create inventory item. No Strain Selected"
            );
          handleCreateInventoryItem(selectedStrainId);
        }}
      >
        Add Strain To Inventory
      </button>
    </div>
  );
};
