import {
  ChangeEvent,
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
  useEffect,
  useState,
} from "react";
import { CreateInventoryParams, Inventory } from "../../types";
import {
  InventoryActionType,
  InventoryContext,
  InventoryDispatchContext,
} from "../../context/inventory";

import { CreateInventory } from "./Create";
import InventoryApi from "../../apis/inventory";
import { InventoryPreview } from "./Preview";
import { Modal } from "../../ui/modal";
import { OrganizationContext } from "../../context/organization";
import { styles } from "../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";

type InventoriesProps = {};
export const Inventories = ({}: InventoriesProps) => {
  const { inventories } = useContext(InventoryContext);
  const inventoryDispatch = useContext(InventoryDispatchContext);
  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const { currentOrganization } = useContext(OrganizationContext);
  const [createOpen, setCreateOpen] = useState(false);
  const [createInventoryState, setCreateInventoryState] =
    useState<CreateInventoryParams>({
      name: "",
      description: "",
    });

  /**
   *
   */
  const handleClickCreate = async (e: any) => {
    setCreateOpen(true);
  };

  /**
   *
   */
  const refreshInventories = async () => {
    if (!user || !user.sub) {
      throw new Error(
        "Unable to refresh inventories. Organization or Organization Id undefined"
      );
    }
    const inventories = await new InventoryApi().getByUser(
      await getAccessTokenSilently(),
      user.sub
    );

    if (inventoryDispatch) {
      inventoryDispatch({
        type: InventoryActionType.set,
        inventories,
      });
    }
  };

  useEffect(() => {
    refreshInventories();
  }, [user]);

  return (
    <section className="flex gap-2 flex-col p-2 rounded-md shadow-md">
      <h1 className="text-2xl">Inventories</h1>
      {/* Create */}
      <section className="flex flex-row gap-2">
        <button onClick={handleClickCreate} className={styles.button.confirm}>
          Create Inventory
        </button>
      </section>
      <section>
        {Array.from(inventories.values()).map((inventory) => (
          <InventoryPreview inventory={inventory} />
        ))}
      </section>
      <section>
        <Modal
          handleClose={() => {
            setCreateOpen(false);
          }}
          open={createOpen}
        >
          <CreateInventory></CreateInventory>
        </Modal>
      </section>
    </section>
  );
};
