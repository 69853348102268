import { styles } from "../styles";

type SearchProps = {
  searchTerm: string;
  handleChangeSearchTerm: (newSearchTerm: string) => void;
  handleClickSearch?: () => void;
};
export const Search = ({
  searchTerm,
  handleChangeSearchTerm,
  handleClickSearch,
}: SearchProps) => {
  return (
    <div className="grid gap-5">
      <input
        className="flex-1 shadow-md p-2 rounded-md w-full"
        placeholder="Blue Dream"
        value={searchTerm}
        onChange={(e) => {
          handleChangeSearchTerm(e.target.value);
        }}
        type="text"
      />
      {handleClickSearch !== undefined && (
        <button
          onClick={handleClickSearch}
          className={styles.button.confirm + " w-full"}
        >
          Search
        </button>
      )}
    </div>
  );
};
