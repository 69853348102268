import { PropsWithChildren } from "react";
import { styles } from "../styles";

export type ModalProps = PropsWithChildren & {
  open: boolean;
  handleClose: () => void;
};
export const Modal = ({ children, open, handleClose }: ModalProps) => {
  return (
    <div
      className={
        open
          ? "absolute grid top-0 left-0  w-full h-full place-content-center"
          : "hidden"
      }
    >
      <div className="bg-white rounded-md shadow-md p-5 z-50 grid ">
        <button
          onClick={handleClose}
          className={
            styles.button.confirm +
            " bg-red-500 p-2 text-xs w-min lg:w-7 lg:h-7 hover:bg-red-700 grid place-content-center justify-self-end"
          }
        >
          X
        </button>
        <div>{children}</div>
      </div>
      <div className="bg-gray-500 absolute opacity-50 w-full h-full "></div>
    </div>
  );
};
