import { Page } from "../../ui/page";
import { Pricing } from "../../components/pricing";

export const PricingPage = () => {
  return (
    <Page header="Pricing" title="Pricing | Batchfinders">
      <Pricing />
    </Page>
  );
};
