export enum RequestType {
  Post = "POST",
  Delete = "DELETE",
  Update = "UPDATE",
  Get = "GET",
}

/**
 * Create REST http requests
 */
export class RequestBuilder {
  /**
   *
   * @param method
   * @param token
   * @param endpoint
   * @returns
   */
  public static buildRequest(
    method: RequestType,
    endpoint: string,
    body?: BodyInit,
    token?: string,
    query?: string
  ): Promise<Response> {
    if (!process.env.REACT_APP_GATEWAY_URL) {
      throw new Error("Unable to create request. No gateway url specified");
    }
    let url: string, init: RequestInit;
    url = `${process.env.REACT_APP_GATEWAY_URL}${endpoint}`;
    init = {
      method,
      headers: this.getRequestHeaders(token),
    };

    if (method != RequestType.Get) {
      init.body = body;
    }

    console.debug(init);
    return fetch(url, init);
  }

  /**
   *
   * @param token
   * @returns
   */
  private static getRequestHeaders(token?: string): HeadersInit {
    return token
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      : {
          "Content-Type": "application/json",
        };
  }
}
