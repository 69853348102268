import { RequestBuilder, RequestType } from "../RequestBuilder";

export class SubscriptionApi {
  async getSubscriptions(userId: string, token: string) {
    const request = RequestBuilder.buildRequest(
      RequestType.Get,
      `/user/${userId}/subscription`,
      undefined,
      token
    );

    const subscriptions = await (await request).json();
    return subscriptions;
  }
}
