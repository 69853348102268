import { useEffect, useState } from "react";

import LoginButton from "../../components/authentication/loginButton";
import { Navigate } from "react-router-dom";
import { styles } from "../../ui/styles";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthenticationPage = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  const images: string[] = [
    "https://res.cloudinary.com/drertcbvx/image/upload/q_auto/v1672769038/batchfinders/batches_pnyez8.webp",
    "https://res.cloudinary.com/drertcbvx/image/upload/q_auto/v1672769037/batchfinders/community_hbsrc3.webp",
    "https://res.cloudinary.com/drertcbvx/image/upload/q_auto/v1672769037/batchfinders/joint_sqw3lf.webp",
  ];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (currentImage + 1 < images.length) {
        setCurrentImage(currentImage + 1);
      } else {
        setCurrentImage(0);
      }
    }, 1000 * 5);
  }, [currentImage]);

  if (!isAuthenticated)
    return (
      <>
        <div
          className="grid place-content-center w-full h-screen"
          style={{
            backgroundImage: `url(${images[currentImage]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={styles.card}>
            <h2 className=" font-bold text-5xl">Welcome To Batchfinders</h2>
            <h3 className=" text-gray-500 italic">
              Embark on your personalized cannabis journey
            </h3>
            <hr></hr>
            <LoginButton></LoginButton>
          </div>
        </div>
      </>
    );
  else if (!isLoading) {
    return <Navigate to="/" />;
  } else {
    return <div>...loading</div>;
  }
};
