import { RequestBuilder, RequestType } from "../RequestBuilder";

export class CustomerApi {
  async getSession(token: string, userId: string) {
    const response = await RequestBuilder.buildRequest(
      RequestType.Get,
      "/user",
      undefined,
      token
    );
    return await response.json();
  }
}
