import { Dispatch, PropsWithChildren, createContext, useReducer } from "react";

import { Organization } from "../../types";

type OrganizationState = {
  currentOrganization: Organization | undefined;
  organizations: Set<Organization>;
};
const defaultOrganizationState: OrganizationState = {
  currentOrganization: undefined,
  organizations: new Set(),
};

export const OrganizationContext = createContext(defaultOrganizationState);
export const OrganizationDispatchContext = createContext<
  Dispatch<OrganizationAction> | undefined
>(undefined);

export const OrganizationContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    OrganizationReducer,
    defaultOrganizationState
  );
  return (
    <OrganizationContext.Provider value={state}>
      <OrganizationDispatchContext.Provider value={dispatch}>
        {children}
      </OrganizationDispatchContext.Provider>
    </OrganizationContext.Provider>
  );
};

export enum OrganizationActionType {
  set,
}
export type OrganizationAction = {
  type: OrganizationActionType;
  organization?: Organization;
  organizations?: Organization[];
};
const OrganizationReducer = (
  state: OrganizationState,
  action: OrganizationAction
): OrganizationState => {
  switch (action.type) {
    case OrganizationActionType.set:
      return {
        ...state,
        currentOrganization: action.organization,
        organizations: new Set(action.organizations),
      };
    default:
      throw new Error("Invalid Organization Action Type");
  }
};
